import React from "react";
import PropTypes from "prop-types";

import "./Dates.scss";
import { FocusGuiding } from "../Focus/FocusGuiding";
import AppLink from "../../ui/links/AppLink";

const Dates = ({ useFullWidth, lang }) => {
  let monday;
  let tuesday;
  let wednesday;
  let thursday;
  let friday;
  let saturday;
  let sunday;
  let june;
  let july;
  let august;
  let september;
  let may;
  let preBooked;

  if (lang === "swedish") {
    preBooked = "Endast förbokade turer";
    may = "Maj";
    june = "Juni";
    july = "Juli";
    august = "Augusti";
    september = "September";
    monday = "Måndag";
    tuesday = "Tisdag";
    wednesday = "Onsdag";
    thursday = "Torsdag";
    friday = "Fredag";
    saturday = "Lördag";
    sunday = "Söndag";

    if (!useFullWidth) {
      monday = "Mån";
      tuesday = "Tis";
      wednesday = "Ons";
      thursday = "Tor";
      friday = "Fre";
      saturday = "Lör";
      sunday = "Sön";
    }
  } else if (lang === "english") {
    preBooked = "Pre-booked tours only";
    may = "May";
    june = "June";
    july = "July";
    august = "August";
    september = "September";
    monday = "Monday";
    tuesday = "Tuesday";
    wednesday = "Wedn.";
    thursday = "Thursday";
    friday = "Friday";
    saturday = "Saturday";
    sunday = "Sunday";

    if (!useFullWidth) {
      monday = "Mon";
      tuesday = "Tue";
      wednesday = "Wed";
      thursday = "Thu";
      friday = "Fri";
      saturday = "Sat";
      sunday = "Sun";
    }
  } else if (lang === "german") {
    preBooked = "Nur vorgebuchte Touren";
    may = "Mai";
    june = "Juni";
    july = "Juli";
    august = "August";
    september = "September";
    monday = "Montag";
    tuesday = "Dienstag";
    wednesday = "Mittwoch";
    thursday = "Donn.";
    friday = "Freitag";
    saturday = "Samstag";
    sunday = "Sonntag";

    if (!useFullWidth) {
      monday = "Mo";
      tuesday = "Di";
      wednesday = "Mi";
      thursday = "Do";
      friday = "Fr";
      saturday = "Sa";
      sunday = "So";
    }
  }

  // <li><span className="blue">2</span></li>
  // <li><span className="purple">11</span></li>
  // <li><span className="green">8</span></li>
  // <li><span className="red">18</span></li>

  // const elem = lang === 'swedish' ? (
  //     <AppLink to="/oland-spirar"><span className="outer-diamond" /><span className="inner-diamond" /></AppLink>
  // ) : null;

  return (
    <div className="calendar-tables">
      <div className="dates-wrapper">
        <div className="dates">
          <div className="month-name">{may} 2025</div>
          <ul className="day-names">
            <li>{monday}</li>
            <li>{tuesday}</li>
            <li>{wednesday}</li>
            <li>{thursday}</li>
            <li>{friday}</li>
            <li>{saturday}</li>
            <li>{sunday}</li>
          </ul>

          <ul className="day-numbers">
            <li className="inactive">28</li>
            <li className="inactive">29</li>
            <li className="inactive">30</li>
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
          </ul>

          <ul className="day-numbers">
            <li>5</li>
            <li>6</li>
            <li>7</li>
            <li>8</li>
            <li>9</li>
            <li className="twin church images">
              <div className="bg bg-church" />
              <div className="bg bg-images" />
              <span className="date">10</span>
            </li>
            <li>11</li>
            {/* <li className="shines">
              <div className="bg" />
              <span className="date">10</span>
            </li>
            <li className="church">
              <div className="bg" />
              <span className="date">11</span>
            </li> */}
          </ul>

          <ul className="day-numbers">
            <li>12</li>
            <li>13</li>
            <li>14</li>
            <li>15</li>
            <li>16</li>
            <li>17</li>
            <li>18</li>
          </ul>

          <ul className="day-numbers">
            <li>19</li>
            <li>20</li>
            <li>21</li>
            <li>22</li>
            <li>23</li>
            <li>24</li>
            <li>25</li>
          </ul>

          <ul className="day-numbers">
            <li>26</li>
            <li>27</li>
            <li>28</li>
            <li>29</li>
            <li>30</li>
            <li>31</li>
            <li className="inactive">1</li>
          </ul>
        </div>

        <div className="dates">
          <div className="month-name">{june} 2025</div>
          <ul className="day-names">
            <li>{monday}</li>
            <li>{tuesday}</li>
            <li>{wednesday}</li>
            <li>{thursday}</li>
            <li>{friday}</li>
            <li>{saturday}</li>
            <li>{sunday}</li>
          </ul>

          <p className="center">
            <i>{preBooked}</i>
          </p>

          {/* <ul className="day-numbers">
            <li className="inactive">27</li>
            <li className="inactive">28</li>
            <li className="inactive">29</li>
            <li className="inactive">30</li>
            <li className="inactive">31</li>
            <li>1</li>
            <li>2</li>
          </ul>

          <ul className="day-numbers">
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li>7</li>
            <li>8</li>
            <li>9</li>
          </ul>

          <ul className="day-numbers">
            <li>10</li>
            <li>11</li>
            <li>12</li>
            <li>13</li>
            <li>14</li>
            <li>15</li>
            <li>16</li>
          </ul>

          <ul className="day-numbers">
            <li>17</li>
            <li>18</li>
            <li>19</li>
            <li>20</li>
            <li>21</li>
            <li>22</li>
            <li>23</li>
          </ul>

          <ul className="day-numbers">
            <li>24</li>
            <li>25</li>
            <li>26</li>
            <li>27</li>
            <li>28</li>
            <li>29</li>
            <li>30</li>
          </ul> */}
        </div>

        <div className="dates">
          <div className="month-name">{july} 2025</div>
          <ul className="day-names">
            <li>{monday}</li>
            <li>{tuesday}</li>
            <li>{wednesday}</li>
            <li>{thursday}</li>
            <li>{friday}</li>
            <li>{saturday}</li>
            <li>{sunday}</li>
          </ul>

          {/* <ul className="day-numbers">
            <li className="inactive">1</li>
            <li className="inactive">2</li>
            <li className="inactive">3</li>
            <li className="inactive">4</li>
            <li className="inactive">5</li>
            <li className="inactive">6</li>
            <li className="inactive">7</li>
          </ul> */}

          <ul className="day-numbers">
            <li className="inactive">30</li>
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
          </ul>

          <ul className="day-numbers">
            <li>7</li>
            <li>8</li>
            <li>9</li>
            <li>10</li>
            {/* <li className="stories">
              <div className="bg" />
              <span className="date">10</span>
            </li> */}
            <li>11</li>
            <li>12</li>
            <li>13</li>
            {/* <li className="_fossil">
              <div className="bg" />
              <span className="date">12</span>
              </li>
              <li className="images">
              <div className="bg" />
              <span className="date">13</span>
              </li>
              <li className="bone">
              <div className="bg" />
              <span className="date">14</span>
              </li> */}
            {/* <li className="stone">
              <div className="bg" />
              <span className="date">16</span>
              </li> */}
          </ul>

          <ul className="day-numbers">
            <li>14</li>
            <li className="fossil">
              <div className="bg" />
              <span className="date">15</span>
            </li>
            <li>
              <a className="link school-link" href="#school-anchor">
                <span className="outer-diamond" />
                <span className="inner-diamond" />
              </a>
              16
            </li>
            <li>17</li>
            {/* <li className="stories">
              <div className="bg" />
              <span className="date">17</span>
            </li> */}
            <li>18</li>
            {/* <li className="images">
              <div className="bg" />
              <span className="date">19</span>
            </li> */}
            <li>19</li>
            <li className="stone">
              <div className="bg" />
              <span className="date">20</span>
            </li>
          </ul>

          <ul className="day-numbers">
            <li>21</li>
            <li className="fossil">
              <div className="bg" />
              <span className="date">22</span>
            </li>
            <li>
              <a className="link school-link" href="#school-anchor">
                <span className="outer-diamond" />
                <span className="inner-diamond" />
              </a>
              23
            </li>
            <li>24</li>
            {/* <li className="stories">
              <div className="bg" />
              <span className="date">24</span>
            </li> */}
            {/* <li>
              <a className="link school-link" href="#school-anchor">
                <span className="outer-diamond" />
                <span className="inner-diamond" />
              </a>
              25
            </li> */}
            <li>25</li>
            {/* <li className="images">
              <div className="bg" />
              <span className="date">26</span>
            </li> */}
            <li>26</li>
            <li>27</li>
            {/* <li className="stone">
              <div className="bg" />
              <span className="date">28</span>
              </li> */}
            {/* 
            <li>28</li>
            <li className="_bone">
            <div className="bg" />
            <span className="date">29</span>
            </li> */}

            {/* <li className="fossil">
              <div className="bg" />
              <span className="date">30</span>
              </li> */}
          </ul>

          <ul className="day-numbers">
            <li>28</li>
            <li className="fossil">
              <div className="bg" />
              <span className="date">29</span>
            </li>
            <li>30</li>
            <li>31</li>
            <li className="inactive">1</li>
            <li className="inactive">2</li>
            <li className="inactive">3</li>
          </ul>
        </div>

        <div className="dates">
          <div className="month-name">{august} 2025</div>
          <ul className="day-names">
            <li>{monday}</li>
            <li>{tuesday}</li>
            <li>{wednesday}</li>
            <li>{thursday}</li>
            <li>{friday}</li>
            <li>{saturday}</li>
            <li>{sunday}</li>
          </ul>

          <ul className="day-numbers">
            <li className="inactive">28</li>
            <li className="inactive">29</li>
            <li className="inactive">30</li>
            <li className="inactive">31</li>
            <li>1</li>
            <li>2</li>
            <li>3</li>
          </ul>

          <ul className="day-numbers">
            <li>4</li>
            <li className="fossil">
              <div className="bg" />
              <span className="date">5</span>
            </li>
            <li>6</li>
            <li>7</li>
            <li>8</li>
            <li>9</li>
            <li>10</li>
          </ul>

          <ul className="day-numbers">
            <li>11</li>
            <li>12</li>
            <li>13</li>
            <li>14</li>
            <li>15</li>
            <li>16</li>
            <li>17</li>
          </ul>

          <ul className="day-numbers">
            <li>18</li>
            <li>19</li>
            <li>20</li>
            <li>21</li>
            <li>22</li>
            <li>23</li>
            <li>24</li>
          </ul>

          <ul className="day-numbers">
            <li>25</li>
            <li>26</li>
            <li>27</li>
            <li>28</li>
            <li>29</li>
            <li>30</li>
            <li>31</li>
          </ul>
        </div>
      </div>

      <FocusGuiding />
    </div>
  );
};

Dates.propTypes = {
  useFullWidth: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Dates;
